
.RenderForScreenshot {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  
  #ready-for-screenshot {
    position: absolute;
    left: -9999px;
    top: 0;
  }
}


